import * as React from "react";
import Layout from "../../components/layout";
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import TopImage from "../../components/topImage";

const ParticipatePage = () => {
  return (
    <Layout>
      <TopImage imageName="participate" />
      <Row>
        <Col>
          <h1 className="mb-4">Participate</h1>
          <p>
            Besides taking part in our speech training to become fluent, there
            are other ways you can participate in our work. If you are a person
            who stutters, your experiences are very important for us and we
            would highly appreciate it if you could give us some more
            information about you and your stutter.
          </p>
          <p>
            Many of the ways to participate are not time consuming. If you want
            to help us, you could do this in one of the following ways:
          </p>
          <ol>
            <li>
              <a href="https://forms.gle/fC13713LGPjfUQrT8" target="_blank">
                Fill out our Basic Speech Survey (you will be redirected to a
                Form that we have created at Google Forms)
              </a>{" "}
              - Duration: 5-10 minutes.
            </li>
            <li>
              Take part in an interview session - Duration: 30-60 minutes.
            </li>
            <li>
              Take part in extended studies, consisting of multiple interviews
              and questionnaires.
            </li>
            <li>Receive free speech training.</li>
            <li>Help us as a volunteer.</li>
          </ol>
          <p>
            If you want to help us in any of these ways or if you have ideas of
            other ways to help us,{" "}
            <Link to="/contact">please don’t hesitate to contact us</Link>!
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default ParticipatePage;
